@function _combinePath($pic) {
  @return $path + $pic;
}

@mixin bg($pic, $x: 100%, $y: 100%) {
  background-image: url(_combinePath($pic));
  background-repeat: no-repeat;
  background-size: $x $y;
}

@mixin text($color, $size, $weight: normal) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin flex($horizontal: center, $vertial: center) {
  display: flex;
  justify-content: $horizontal;
  align-items: $vertial;
}