@import '../../styles/mixin.scss';
$path: '../../assets/';

.header{margin:0 auto;width:1200px;
  .headerCon{@include flex(space-between);width:100%;height:100px;
    .logo{width:306px;height:62px;
      img{width:auto;height:100%;}
    }
    .navs{@include flex();flex-grow:1;height:100%;
      >a{position:relative;@include flex();width:240px;height:100%;box-sizing:border-box;@include text(#807AD4, 32px, bold);
        &.on{background-color:#807AD4;color:#fff;
          &::after{position:absolute;bottom:0;left:0;content:quote(' ');width:100%;height:10px;background:#BFC1FF;}
        }
      }
    }
  }
}