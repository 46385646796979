@import '../../styles/mixin.scss';
$path: '../../assets/';

.content{margin:40px auto 0;width:1200px;
  .box{margin:200px auto;@include flex();width:630px;height:180px;border:2px solid #675BA6;background:#F4F5FF;border-radius:10px;
    .logo{position:relative;@include flex();display:inline-flex;width:280px;height:100%;
      .icon{margin-top:-30px;width:252px;height:220px;@include bg('icon.png');}
    }
    .info{@include flex(center, flex-start);display:inline-flex;flex-direction:column;flex-grow:1;
      p{margin:5px 0;@include text(#675BA6, 26px, bold);}
    }
  }
}