@import '../../styles/mixin.scss';

.content{margin:40px auto 0;width:1200px;
  .swiperWrap{
    .poster{width:100%;height:auto;}
  }
  .area{padding-top:20px;
    .card{@include flex();padding:10px 0;
      h3{position:relative;@include flex();flex-shrink:0;width:180px;height:60px;cursor:pointer;background-color:#807AD4;@include text(#ffffff, 22px, bold);
        &:hover{background-color:#8e88e0;}
      }
      .cont{flex-grow:1;padding-left:60px;
        p{margin:10px 0;@include text(#2E40B0, 18px);}
      }
    }
  }
}